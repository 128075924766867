body {
    /* background-color: #faf2db; */
    /* background-color: #fff; */
    /* background-color: #f5f5f5; */
    /* padding: 20px; */
    padding: 0px;
    margin: 0;
    margin-bottom: 10px;
}

h1, h2, p, ul, li {
  font-family: sans-serif;
}
ul.header li {
  display: inline;
  list-style-type: none;
  margin: 0;
}
ul.header {
  background-color: #111;
  padding: 0;
}
ul.header li a {
  color: #FFF;
  font-weight: bold;
  text-decoration: none;
  padding: 20px;
  display: inline-block;
}
.content {
  background-color: #FFF;
  padding: 20px;
  border-radius: 5px;
}
.content h2 {
  padding: 0;
  margin: 0;
}
.content li {
  margin-bottom: 10px;
}

ul.footer {
  /* background-color: #111; */
  padding: 0;
}
ul.footer li {
  display: inline;
  color: #111;
  /* color: #fff; */
  list-style-type: none;
  margin: 0;
  padding: 20px;
}
.header-right li {
    align-items: flex-end;
}
ul.footer li a {
  color: #111;
  text-decoration: none;
  padding: 20px;
  display: inline-block;
}

.menu-bar {
  /* background-color: #dcdcdc; */
  background-color: #f5f5f5;
  /* background-color: #111; */
  /* background-color: #fff; */
  padding: 0px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  color: #000;
  font-weight: bold;
  text-decoration: none;
  font-size: 20px;
  margin: 0;
}

#navitem-nodeco {
  text-decoration: none;
}

@media print {
  .print-hide {
    display: none;
  }
  .print-show {
    display: block;
  }
}