/* Regular Desktop+ */
.cover {
    display: flex;
    opacity: 0.9;
    justify-content: space-between;
    align-items: center;
    /* padding: 1.5rem 6rem 30rem 6rem; */
    /* padding: 1.5rem 9rem; */
    max-height: 70vh;
}

.coverButton {
    transition: .5s ease;
    opacity: 0.92;
    width: 120%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
    background-color: #007bff;
    color: white;
    font-size: 1.5em;
    font-weight: bold;
    padding: 2.5rem;
}

/* Mobile */
@media (max-width: 700px) {
    .cover {
        opacity: 0.9;
        padding: 0rem;
        max-height: 100vh;
    }

    .coverButton {
    opacity: 0.9;
      /* padding: 2.5rem; */
      font-size: 0.75em;
      padding: 1rem;
    }
}

/* Tablet and Smaller Desktop */
@media (min-width: 701px) and (max-width: 1120px) {
    .cover {
        display: flex;
        opacity: 0.9;
        justify-content: space-between;
        align-items: center;
        /* padding: 1.5rem 6rem 30rem 6rem; */
        padding: 0rem;
        max-height: 300vh;
    }

    .coverButton {
        opacity: 0.9;
        width: 180%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        /* padding: 2rem 6rem 2rem 6rem; */
        font-size: 1em;
        padding: 1.5rem;
    }
}
